<template>
  <v-card id="cw-confirmation-dialog--dialog__failure-technical">
    <v-card-title id="cw-confirmation-dialog__title" class="text-h5">
      <translate>
        Company not found
      </translate>
    </v-card-title>
    <v-card-text id="cw-confirmation-dialog__text">
      <p v-translate>
        The company ID you provided could not be found.
      </p>
      <p v-translate>
        Please check the ID and try again.
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        id="cw-confirmation-dialog__confirm"
        :loading="$wait.waiting('confirmation_dialog__confirm_processing')"
        :disabled="$wait.waiting('confirmation_dialog__confirm_processing')"
        color="primary"
        text
        @click="
          $emit('dialog-cancel');
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Close
        </translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CwDialogCompanyNotFound',
};
</script>
